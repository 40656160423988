import React, { useCallback, useEffect, useRef } from 'react';

import useDevice from '@/hooks/useDevice';

const RC_PROXY_REVERT_STYLE_ID = 'rc-proxy-revert';

const useProxyWrapperStyle = (
  divWrapperForSyleNode: React.MutableRefObject<HTMLDivElement | null>,
  appProxy: Maybe<Returns.AppProxyInfo>,
) => {
  const isMobile = useDevice().mobile;

  const appNode = useRef<HTMLElement | null>(null);

  const proxySyleNode = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    proxySyleNode.current = document.querySelector(`#${RC_PROXY_REVERT_STYLE_ID}`);

    appNode.current = document.querySelector('#__next');
    appNode.current?.setAttribute('style', 'width: 100%;');
  }, []);

  // const setProxyStyle = useCallback(
  //   (styleStr: string) => {
  //     if (proxySyleNode.current) {
  //       proxySyleNode.current.innerText = styleStr;
  //     } else {
  //       const style = document.createElement('style');
  //       style.setAttribute('id', RC_PROXY_REVERT_STYLE_ID);
  //       style.innerText = styleStr;
  //       document.body.append(style);
  //       proxySyleNode.current = document.querySelector(`#${RC_PROXY_REVERT_STYLE_ID}`);
  //     }
  //   },
  //   [proxySyleNode],
  // );

  const getProxyWrapperStyle = useCallback(() => {
    let shopifyStoreHeaderHeight = 0;
    const { customizedConfig } = appProxy || {};

    const top = appNode.current?.offsetTop || 0;
    const fullScreenHeight = window.innerHeight - top;

    const homePageContentHeight = isMobile
      ? fullScreenHeight
      : Math.max(fullScreenHeight * 0.8, 625);

    if (customizedConfig?.headerSelector) {
      const headerEle = document.querySelector(customizedConfig.headerSelector);
      headerEle && (shopifyStoreHeaderHeight = headerEle.clientHeight);
    }
    // 设置了 --proxy-content-height variable，后面每个页面的 step card 需要通过这个变量来计算高度
    return `
        margin-top: ${shopifyStoreHeaderHeight}px;
        --proxy-content-height: ${homePageContentHeight - shopifyStoreHeaderHeight}px 
      `;
  }, [appProxy, appNode, isMobile]);

  const setWrapperStyle = useCallback(() => {
    if (divWrapperForSyleNode.current && appNode.current) {
      divWrapperForSyleNode.current?.setAttribute('style', getProxyWrapperStyle());
    }
  }, [divWrapperForSyleNode, getProxyWrapperStyle, appNode]);

  return setWrapperStyle;
};

export default useProxyWrapperStyle;
