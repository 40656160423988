import { useRouter } from 'next/router';
import { ReactNode, useRef } from 'react';
import useLayoutEffect from 'use-isomorphic-layout-effect';

import { appProxyClass } from '@/styles/global.css';

import useProxyWrapperStyle from '../hooks/useProxyWrapperStyle';

interface ReturnsPageProps {
  children: ReactNode;
  appProxy: Maybe<Returns.AppProxyInfo>;
}
export const ProxyStyle = ({ children, appProxy }: ReturnsPageProps) => {
  const router = useRouter();
  const divWrapperForSyleNode = useRef(null);

  const toggleProxyWrapperStyle = useProxyWrapperStyle(divWrapperForSyleNode, appProxy);
  /**
   * WARNING: DOT NOT Move this logic into useEffect
   * nextjs will look for the meta with the name 'next-head-count' directly under the header and read the content directly,
   * nextjs cannot add the meta correctly in proxy mode, resulting in an error.
   */
  useLayoutEffect(() => {
    const headEl = document.getElementsByTagName('head')[0];
    const headCountEl = headEl.querySelector('meta[name=next-head-count]');

    if (!headCountEl) {
      const meta = document.createElement('meta');
      meta.name = 'next-head-count';
      meta.content = '0';
      headEl.appendChild(meta);
    }
  }, [appProxy]);

  useLayoutEffect(() => {
    const ob = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // shopper 的实际内容可能会是动态加载，直接设置 style 可能会设置到 suspense loading view 上
          // 监听到子元素变化时也需要再次设置
          toggleProxyWrapperStyle();
        }
      }
    });

    if (divWrapperForSyleNode.current) {
      toggleProxyWrapperStyle();

      ob.observe(divWrapperForSyleNode.current, { childList: true, subtree: true });
    }

    return () => {
      ob.disconnect();
    };
  }, [toggleProxyWrapperStyle, router.route]);

  useLayoutEffect(() => {
    appProxy && router.events.on('routeChangeStart', toggleProxyWrapperStyle);

    return () => {
      appProxy && router.events.off('routeChangeStart', toggleProxyWrapperStyle);
    };
  }, [appProxy, router.events, toggleProxyWrapperStyle]);

  return (
    <div ref={divWrapperForSyleNode} className={appProxyClass}>
      {children}
    </div>
  );
};
